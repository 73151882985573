import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-realization',
  templateUrl: './realization.component.html',
  styles: []
})
export class RealizationComponent implements OnInit {


  @Input() realization;
  shown: boolean;

  constructor() { }

  ngOnInit() {
  }

}
