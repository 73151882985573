import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { HttpService } from "./http.service";
import { Company } from "./company.service";

interface User {
  id: number;
  phone: number;
  scope: number;
  email: string;
}

interface Response {
  data: {
    response: {
      data;
    };
  };
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user = new Subject<User>();

  constructor(private httpService: HttpService) {}

  getUser = () => {};

  sendSms(phone: number) {
    console.log(phone);
    //return "zalida";
    return this.httpService.postBuild("/common/sendSmsNew", { phone });
  }
  checkSms(key: string, sms: string): Observable<any> {
    return this.httpService.postBuild("/common/checkSmsNew", { key, sms });
  }
}
