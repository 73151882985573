import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface Company {
  id: number;
  expired: string;
  name?: string;
}

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  private company: Company;

  constructor() {
    // let company: Company;
    // if (localStorage.getItem('company')) {
    //   company = JSON.parse(localStorage.getItem('company'));
    //   this.company = company;
    // }
  }

  // removeCompany(){

  //   localStorage.removeItem('company');

  //   this.company = null;

  // }

  // setCompany(company: Company){
  //   localStorage.setItem('company', JSON.stringify(company));
  //   this.company = company;
  // }

  // getCompany(): Company {
  //    return this.company;
  // }

  // isAuth() {
  //   let auth = false;
  //   if (this.getCompany() && this.getCompany().expired && new Date() < new Date(this.getCompany().expired)) {
  //     auth = true;
  //   } else {
  //     localStorage.clear();
  //   }
  //   return auth;
  // }
}
