import { Component, OnInit } from "@angular/core";
import { CompanyService } from "../service/company.service";
import { DataService, Promocode, Reward } from "../service/data.service";
import { Product, ProductsService } from "../service/products.service";
import {
  RealizationService,
  ReceivedCertificate,
} from "../service/realization.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  products: Product[];
  realizations: any[];
  requisites: any[];
  notPaidPoints = 0;
  widthdraws: any[];

  totalPlus = 0;
  mode = 1;
  certificateSelect = false;
  requisiteActive;
  receivedCertificates: ReceivedCertificate[];
  month = "";

  requisitesObj: any;

  loading: boolean;
  rewards: Reward[];
  promocodes: Promocode[];
  total = 0;
  availableTotal = 0;
  lockedTotal = 0;
  notPaidTotal = 0;

  constructor(
    private companyService: CompanyService,
    private realizationService: RealizationService,
    private productService: ProductsService,
    private dataService: DataService
  ) {}

  getData() {
    this.loading = true;

    this.dataService
      .getData(this.dataService.getBonusPerson().id)
      .subscribe((data) => {
        data = data.response.data;
        this.promocodes = data.promocodes.filter(
          (p) =>
            !p.dateRecieve ||
            (p.dateRecieve &&
              new Date(p.dateRecieve.date) >
                new Date(
                  new Date().getFullYear() - 1,
                  new Date().getMonth(),
                  new Date().getDate()
                ))
        );
        this.rewards = data.rewards;
        this.total = data.total;
        this.availableTotal = data.availableTotal;
        this.notPaidTotal = data.notPaidTotal;
        this.lockedTotal = data.lockedTotal;
        this.loading = false;
      });
  }

  message() {
    return new Date() < new Date(2021, 11, 25);
  }

  showCode(promocode) {
    this.dataService.showCode(promocode.id).subscribe(
      (data) => {
        if (data.response.data) {
          promocode.showCode = true;
        } else {
          alert("Попробуйте еще раз.");
        }
      },
      (error) => {
        alert("Попробуйте еще раз.");
      }
    );
  }

  ngOnInit() {
    const months = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];

    this.getData();
    let date = new Date();
    if (date.getDate() < 15) {
      this.month = months[new Date().getMonth()];
    } else {
      this.month =
        months[new Date(date.setMonth(date.getMonth() + 1)).getMonth()];
    }

    // this.realizationService.dateNow$.subscribe((date) => {
    //   //console.log(date.getDate())
    //   if (date.getDate() < 15) {
    //     this.month = months[new Date().getMonth()];
    //   } else {
    //     this.month =
    //       months[new Date(date.setMonth(date.getMonth() + 1)).getMonth()];
    //   }
    // });

    // this.realizationService.mode$.subscribe((mode) => {
    //   this.mode = mode;
    // });

    // this.productService.products$.subscribe((data) => {
    //   this.products = data;
    // });

    // this.realizationService.notPaidPoints$.subscribe((data) => {
    //   this.notPaidPoints = data;
    // });

    // this.realizationService.loading$.subscribe((data) => {
    //   this.loading = data;
    // });

    // this.realizationService.requisites$.subscribe((data) => {
    //   console.log("lol inside");
    //   this.requisites = data;

    //   this.requisitesObj = [];

    //   this.total = 0;
    //   this.totalPlus = 0;

    //   for (const item of this.requisites) {
    //     const name = item.contractor;
    //     this.requisitesObj[item.contractor] = item;

    //     this.total += item.points;
    //     this.totalPlus += item.pointsAfter;
    //   }
    // });

    // this.realizationService.realizations$.subscribe((data) => {
    //   console.log(data);
    //   this.realizations = data;
    // });

    // this.realizationService.receivedCertificates$.subscribe((data) => {
    //   this.receivedCertificates = data;
    // });

    // this.productService.getProducts();
    // this.realizationService.getRealizations(this.companyService.getCompany());
  }

  logout() {
    this.dataService.removeBonusPerson();
  }

  showCertificateSelect() {
    // this.requisiteActive = requisite;
    this.certificateSelect = true;
  }

  hideCertificateSelect(event) {
    event.stopPropagation();
    event.preventDefault();
    this.certificateSelect = false;
  }
}
