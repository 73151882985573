import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AuthFormComponent } from './auth-form/auth-form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaskDirective } from './mask.directive';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RusEndingPipe } from './rus-ending.pipe';
import { NumberFormatPipe } from './number-format.pipe';
import { CertificateFormComponent } from './certificate-form/certificate-form.component';
import { RealizationComponent } from './realization/realization.component';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [
    AppComponent,
    AuthFormComponent,
    MaskDirective,
    DashboardComponent,
    RusEndingPipe,
    NumberFormatPipe,
    CertificateFormComponent,
    RealizationComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'ru' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
