import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Observable } from "rxjs";
import { Md5 } from "md5-typescript";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class HttpService {
  constructor(
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  public postBuild(url: string, data: any = {}): Observable<any> {
    data = !data ? {} : data;
    data.time = new Date().getTime();
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded"
    });
    const secretKey = this.storage.get("secretKey");
    const accessToken = this.storage.get("accessToken");
    const signature = this.generateSignature(data);
    let body = data ? "params=" + JSON.stringify(data) : null;
    accessToken
      ? (body += "&accessToken=" + accessToken + "&signature=" + signature)
      : null;

    return this.http.post(environment.baseURI + url, body, {
      headers: httpHeaders
    });
  }

  public generateSignature(data: Object) {
    const secretKey = this.storage.get("secretKey");
    // console.log("secretkey", secretKey);

    return Md5.init(JSON.stringify(data) + secretKey);
  }
}
