import { Component, OnInit } from "@angular/core";
import { CompanyService } from "./service/company.service";
import { DataService } from "./service/data.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "bonus";

  constructor(
    public companyService: CompanyService,
    public dataService: DataService
  ) {}

  ngOnInit(): void {}
}
