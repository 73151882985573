import { Directive, HostListener, ElementRef, Input, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appMask]'
})
export class MaskDirective  implements OnInit, OnDestroy{

  @Input('appMask') mask: string;
  @Input() pattern: string;
  private sub: Subscription;
  private input: AbstractControl;
  @Input() set phoneControl(control: AbstractControl){
    this.input = control;
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(){
    this.phoneValidate();
 }

 ngOnDestroy() {
   this.sub.unsubscribe();
 }

 phoneValidate() {
  this.sub = this.input.valueChanges.subscribe(value => {
      const mask = this.mask;
      const literalPattern = /[0\*]/;
      const numberPattern = /[0-9]/;
      let newValue = '';

      try {
        const maskLength = mask.length;
        let valueIndex = 0;
        let maskIndex = 0;

        for (; maskIndex < maskLength;) {
          if (maskIndex >= value.length) break;

          if (mask[maskIndex] === "0" && value[valueIndex].match(numberPattern) === null) break;

          // Found a literal
          while (mask[maskIndex].match(literalPattern) === null) {
            if (value[valueIndex] === mask[maskIndex]) break;
            newValue += mask[maskIndex++];
          }
          newValue += value[valueIndex++];
          maskIndex++;
        }

        //input.value = newValue;

        this.input.setValue(newValue, {emitEvent: false});

      } catch (e) {
        console.log(e);
      }
    });




    
  }



}
