import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "./http.service";
import { Company, CompanyService } from "./company.service";
import { DataService } from "./data.service";

/*interface Realization {

}*/
export interface Certificate {
  price: number;
}

export interface ReceivedCertificate {
  price: number;
  id: number;
  requisiteId: number;
  promocode: string;
  dateReceive: Date;
}

@Injectable({
  providedIn: "root",
})
export class RealizationService {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  mode$: BehaviorSubject<number> = new BehaviorSubject(1);

  notPaidPoints$: BehaviorSubject<number> = new BehaviorSubject(0);
  realizations$: BehaviorSubject<[]> = new BehaviorSubject([]);
  requisites$: BehaviorSubject<[]> = new BehaviorSubject([]);
  certificates$: BehaviorSubject<Certificate[]> = new BehaviorSubject([]);
  receivedCertificates$: BehaviorSubject<
    ReceivedCertificate[]
  > = new BehaviorSubject([]);

  dateNow$: BehaviorSubject<Date> = new BehaviorSubject(new Date());

  constructor(
    http: HttpClient,
    private httpService: HttpService,
    private companyService: CompanyService,
    private dataService: DataService
  ) {}

  getRealizations(company: Company) {
    this.loading$.next(true);
    console.log("getreal");
    return this.httpService
      .postBuild("/bonus/getRealizations?companyId=" + company.id)
      .subscribe((data) => {
        const objs = [];

        function sortByDate(a, b) {
          if (a.date > b.date) {
            return -1;
          }
          if (a.date < b.date) {
            return 1;
          }
          return 0;
        }

        function sortByScope(a, b) {
          if (a.points > b.points) {
            return -1;
          }
          if (a.points < b.points) {
            return 1;
          }
          return 0;
        }
        // objs.sort( compare );

        // this.products$.next(objs);

        // const realizations = <any>[];

        const requisites = data.response.data.requisites;
        requisites.sort(sortByScope);
        let notPaidPoints = 0;

        const realizations = data.response.data.realizations;
        const realizationsNotPaid = data.response.data.realizationsNotPaid;
        const backs = data.response.data.backs;
        const certificates = data.response.data.certificates;

        for (const k in backs) {
          backs[k].points = 0;
          for (const kk in backs[k].products) {
            backs[k].points += backs[k].products[kk].points;
          }
        }

        for (const k in realizationsNotPaid) {
          realizationsNotPaid[k].points = 0;
          for (const kk in realizationsNotPaid[k].products) {
            realizationsNotPaid[k].points +=
              realizationsNotPaid[k].products[kk].points;
            notPaidPoints += realizationsNotPaid[k].products[kk].points;
          }
        }

        for (const k in realizations) {
          realizations[k].points = 0;
          for (const kk in realizations[k].products) {
            realizations[k].points += realizations[k].products[kk].points;
          }
        }

        realizations.sort(sortByDate);
        backs.sort(sortByDate);
        certificates.sort(sortByDate);

        for (const k in requisites) {
          requisites[k].points = 0;
          requisites[k].backsTotal = 0;
          requisites[k].certificateTotal = 0;
          requisites[k].minusPoints = 0;
          requisites[k].pointsAfter = 0;

          for (const kk in realizations) {
            if (realizations[kk].contractor == requisites[k].contractor) {
              if (realizations[kk].before === true) {
                requisites[k].points += realizations[kk].points;
              } else {
                requisites[k].pointsAfter += realizations[kk].points;
              }
            }
          }

          for (const kk in backs) {
            if (backs[kk].contractor == requisites[k].contractor) {
              if (backs[kk].before === true) {
                requisites[k].points -= backs[kk].points;
                requisites[k].backsTotal += backs[kk].points;
              } else {
                requisites[k].pointsAfter -= backs[kk].points;
                requisites[k].backsTotalAfter += backs[kk].points;
              }

              // requisites[k].points -= backs[kk].points
              // requisites[k].backsTotal += backs[kk].points
            }
          }

          for (const kk in certificates) {
            if (certificates[kk].contractor == requisites[k].contractor) {
              // requisites[k].points -= certificates[kk].points
              // requisites[k].certificateTotal += certificates[kk].points
              requisites[k].points -= certificates[kk].price;

              if (certificates[kk].before === true) {
                requisites[k].certificateTotal += certificates[kk].price;
              } else {
                //requisites[k].pointsAfter -= certificates[kk].points;
                requisites[k].certificateTotalAfter += certificates[kk].price;
              }
            }
          }

          requisites[k].minusPoints =
            requisites[k].backsTotal + requisites[k].certificateTotal;
          requisites[k].minusPointsAfter =
            requisites[k].backsTotalAfter + requisites[k].certificateTotalAfter;
        }

        requisites.sort(sortByScope);

        /*for(let item of reals){
        for(let real of item.realizations){
          real.requisite = item.requisite
          realizations.push(real)
        }
      }*/
        /*for(let kr in requisites){
        requisites[kr].points = 0;
        if(requisites[kr].contractor == realizations[k].contractor ){
          requisites[kr].points += realizations[k].products[kk].points
        }
      }*/

        /*const requisites = <any>[];
      const req = data.response.data.realizations;
      for(let item of req){
        requisites.push(item.requisite)
      }*/

        this.dateNow$.next(new Date(data.response.data.date.date));

        this.notPaidPoints$.next(notPaidPoints);
        this.requisites$.next(requisites);
        this.realizations$.next(realizations);
        this.receivedCertificates$.next(certificates);

        this.loading$.next(false);
      });
  }

  getCertificates() {
    this.certificates$.next([]);
    this.httpService.postBuild("/bonus/getCertificates").subscribe((data) => {
      this.certificates$.next(data.response.data);
    });
  }

  buyCertificate(certificate: Certificate) {
    if (
      confirm(
        'Подтвердите, что хотите приобрести сертификат или нажмите "отмена". '
      )
    ) {
      this.httpService
        .postBuild("/bonus/buyCertificateNew", {
          bonusPersonId: this.dataService.getBonusPerson().id,
          certificatePrice: certificate.price,
        })
        .subscribe((data) => {
          this.dataService.getData(this.dataService.getBonusPerson().id);
          this.mode$.next(5);
        });
    }
  }
}
