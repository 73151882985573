import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rusEnding'
})
export class RusEndingPipe implements PipeTransform {

  transform(value: number, n1: string, n2: string, n3: string): string {
    if (value >= 11 && value <= 19) { return n3; }
    value = value % 10;
    if (value === 1) { return n1; }
    if (value >= 2 && value <= 4) { return n2; }
    return n3;
  }


}
