import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { UserService } from "../service/user.service";
import { CompanyService } from "../service/company.service";
import { DataService } from "../service/data.service";

@Component({
  selector: "app-auth-form",
  templateUrl: "./auth-form.component.html",
  styleUrls: ["./auth-form.component.scss"],
})
export class AuthFormComponent implements OnInit {
  authForm: FormGroup;
  smsForm: FormGroup;
  loading = false;
  message = false;
  shown = true;
  error = null;
  key = "";
  stepForm = 1;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private companyService: CompanyService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.authForm = this.formBuilder.group({
      phone: new FormControl("+7", [
        Validators.required,
        Validators.pattern(
          /^\+7 \([0-9]{3}\) [0-9]{3}[\-][0-9]{2}[\-][0-9]{2}$/
        ),
      ]),
    });

    this.smsForm = this.formBuilder.group({
      sms: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[0-9]{4}$/),
      ]),
    });
  }

  onSubmitAuth() {
    const phone = this.authForm.value.phone;

    const res$ = this.userService
      .sendSms(phone.replace(/([^\d]*)/g, ""))
      .subscribe((data) => {
        if (data.response.data.error) {
          console.log(data);
          alert(data.response.data.errorMsg);
          return;
        }

        this.stepForm = 2;
        this.key = data.response.data.key;
      });
  }

  onSubmitSms() {
    const sms = this.smsForm.value.sms;
    console.log("onsubmit sms");
    const res$ = this.userService.checkSms(this.key, sms).subscribe((data) => {
      if (data.response.data.error === true) {
        alert("Error");
        return;
      }
      console.log("setting company");
      //this.companyService.setCompany(data.response.data.company);

      this.dataService.setBonusPerson(data.response.data.bonusPerson);

      this.stepForm++;
    });
  }
}
