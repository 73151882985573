import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  RealizationService,
  Certificate,
} from "../service/realization.service";

@Component({
  selector: "app-certificate-form",
  templateUrl: "./certificate-form.component.html",
  styleUrls: ["./certificate-form.component.scss"],
})
export class CertificateFormComponent implements OnInit {
  certificates: Certificate[];
  certificateActive: Certificate;

  certificatesAll = [
    { price: 500 },
    { price: 700 },
    { price: 1000 },
    { price: 2000 },
    { price: 3000 },
    { price: 5000 },
    { price: 8000 },
    { price: 10000 },
    { price: 25000 },
    { price: 50000 },
  ];

  @Output() onClose: EventEmitter<void> = new EventEmitter();

  @Output() onBuy: EventEmitter<void> = new EventEmitter();

  @Input() availableValue;

  constructor(private realizationService: RealizationService) {}

  ngOnInit() {
    console.log(this.availableValue);
    /* this.realizationService.getCertificates();
    this.realizationService.certificates$.subscribe(data => {

      const certs = [];
      for (let k in data) {
        let cert = data[k];
        if (cert.price <= this.requisite.scopeTotal) {
          certs.push(cert);
        }
      }
      console.log(certs)
      this.certificates = certs;

      if (this.certificates.length > 0) {
        this.certificateActive = this.certificates[0];
      }


    });*/
  }

  hideCertificateSelect(event) {
    this.onClose.emit();
  }

  buyCertificate() {
    this.realizationService.buyCertificate(this.certificateActive);
    this.onBuy.emit();
    this.hideCertificateSelect("");
  }
}
