import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


export interface Product {
  name: string;
  photo: string;
  scope: number;
  link: string;
  id?: number;
}


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  products$: Subject<Product[]> = new Subject();

  constructor() {
  }


  getProducts() {
      const objs = [
        {
          name: 'Ароматический брелок Квадрат Чистый хлопок',
          scope: 2990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/101.png',
          link: 'https://www.ozon.ru/context/detail/id/154345152/'
        },
        {

          name: 'Молот Тора',
          scope: 1990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/102.jpg',
          link: 'https://www.ozon.ru/context/detail/id/159056704/'

        },
        {

          name: 'Мини телефон-брелок Bx8',
          scope: 2290,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/103.png',
          link: 'https://www.ozon.ru/context/detail/id/161511609/'

        },
        {

          name: 'Victorinox Classic Midnight MiniChamp, 58 мм, 17 функций',
          scope: 5187,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/104.jpg',
          link: 'https://www.ozon.ru/context/detail/id/155982445/'

        },
        {

          name: 'AccesStyle Cornflower TWS',
          scope: 2990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/105.jpg',
          link: 'https://www.ozon.ru/context/detail/id/160367994/'

        },
        {

          name: 'TWS i5',
          scope: 2999,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/106.jpg',
          link: 'https://www.ozon.ru/context/detail/id/152729803/'

        },
        {

          name: 'Baseus SIMU Active Noise Reduction Wireless earphone S15 Black',
          scope: 2999,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/107.jpg',
          link: 'https://www.ozon.ru/context/detail/id/160960049/'

        },
        {

          name: 'JBL Tune 600BTNC',
          scope: 2840,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/108.jpg',
          link: 'https://www.ozon.ru/context/detail/id/149033628/'

        },
        {

          name: 'Nespresso Essenza Mini Bundle C30',
          scope: 7990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/109.png',
          link: 'https://www.ozon.ru/context/detail/id/149721230/'

        },
        {

          name: 'Profi Cook PC-ES 1109',
          scope: 18960,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/110.png',
          link: 'https://www.ozon.ru/context/detail/id/149721230/'

        },
        {

          name: 'De`Longhi DeLonghi ESAM 4000',
          scope: 18960,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/111.png',
          link: 'https://www.ozon.ru/context/detail/id/5055819/'

        },
        {

          name: 'Philips 3100 EP3558/00',
          scope: 29742,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/112.png',
          link: 'https://www.ozon.ru/context/detail/id/143165025/'

        },
        {

          name: 'Samsung Galaxy Tab S6 Wi-Fi (2019), 128 GB',
          scope: 43440,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/113.png',
          link: 'https://www.ozon.ru/context/detail/id/156604929/'

        },
        {

          name: '10.5\' Планшет Apple iPad Pro Wi-Fi (2017), 512 GB',
          scope: 61368,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/114.jpg',
          link: 'https://www.ozon.ru/context/detail/id/141384167/'

        },
        {

          name: '12\' Ноутбук Apple MacBook (MNYF2RU/A)',
          scope: 79855,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/115.png',
          link: 'https://www.ozon.ru/context/detail/id/141542726/'

        },
        {

          name: '15.6\' Игровой ноутбук MSI GL65 9SDK-081RU (9S7-16U512-081)',
          scope: 82565,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/116.png',
          link: 'https://www.ozon.ru/context/detail/id/161027813/'

        },
        {

          name: 'Фитнес-браслет Xiaomi Mi Band 4',
          scope: 2990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/117.jpg',
          link: 'https://www.ozon.ru/context/detail/id/154739552/'

        },
        {

          name: 'Apple Watch Series 4 GPS A1977, MU672, 40 мм',
          scope: 25408,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/118.jpg',
          link: 'https://www.ozon.ru/context/detail/id/149369257/'

        },
        {

          name: 'Apple Watch Series 5 GPS, 44 мм',
          scope: 34490,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/119.jpg',
          link: 'https://www.ozon.ru/context/detail/id/161777944/'

        },
        {

          name: 'Garmin Fenix 6X',
          scope: 71000,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/120.png',
          link: 'https://www.ozon.ru/context/detail/id/161594349/'

        },
        {

          name: 'PlayStation4 Pro 1ТБ в комплекте с ваучером Fortnite Neo Versa',
          scope: 29990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/121.jpg',
          link: 'https://www.ozon.ru/context/detail/id/156808451/'

        },
        {

          name: 'Microsoft Xbox One X, черный + игра Red Dead Redemption 2',
          scope: 29990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/122.png',
          link: 'https://www.ozon.ru/context/detail/id/161251669/'

        },
        {

          name: 'PlayStation4 Pro 1ТБ в комплекте с игрой Death Stranding',
          scope: 30999,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/123.png',
          link: 'https://www.ozon.ru/context/detail/id/161633835/'

        },
        {

          name: 'Xbox One 1 ТБ + Halo 5: Guardians',
          scope: 38623,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/124.jpg',
          link: 'https://www.ozon.ru/context/detail/id/34075215/'

        },
        {

          name: 'Apple iPhone XS Max 4/512GB',
          scope: 96838,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/125.jpg',
          link: null

        },
        {

          name: 'Samsung Galaxy S10+ 12/1000GB',
          scope: 109999,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/126.jpg',
          link: null

        },
        {

          name: 'Apple iPhone 11 Pro 4/256GB',
          scope: 103990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/127.jpg',
          link: null

        },
        {

          name: 'Apple iPhone 11 Pro Max 4/512GB',
          scope: 131990,
          photo: 'http://old.etsgroup.ru/assets/img/used_photo/pics/128.jpg',
          link: 'https://www.ozon.ru/context/detail/id/157937324/'

        }
      ];

      function compare( a, b ) {
        if ( a.scope > b.scope ) {
          return -1;
        }
        if ( a.scope < b.scope ) {
          return 1;
        }
        return 0;
      }

      objs.sort( compare );


      this.products$.next(objs);
  }
}
