import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

export interface BonusPerson {
  id: number;
  expired: string;
}

export interface Promocode {
  id: number;
  price: number;
  promocode: string;
  recieved: boolean;
  dateCreate: Date;
  dateRecieve: Date;
}

export interface Reward {
  id: number;
  title: string;
  typeTitle: string;
  value: number;
  dateCreate: Date;
}

@Injectable({
  providedIn: "root",
})
export class DataService {
  private bonusPerson: BonusPerson;

  constructor(private httpService: HttpService) {
    if (localStorage.getItem("bonusPerson")) {
      let bonusPerson = JSON.parse(localStorage.getItem("bonusPerson"));
      this.bonusPerson = bonusPerson;
    }
  }

  getData(bonusPersonId: number) {
    return this.httpService.postBuild("/bonus/getData/" + bonusPersonId);
  }

  showCode(codeId: number) {
    return this.httpService.postBuild("/bonus/showCode/" + codeId);
  }

  removeBonusPerson() {
    localStorage.removeItem("bonusPerson");
    this.bonusPerson = null;
  }

  setBonusPerson(bonusPerson: BonusPerson) {
    localStorage.setItem("bonusPerson", JSON.stringify(bonusPerson));
    this.bonusPerson = bonusPerson;
  }

  getBonusPerson(): BonusPerson {
    return this.bonusPerson;
  }

  isAuth() {
    let auth = false;
    if (
      this.getBonusPerson() &&
      this.getBonusPerson().expired &&
      new Date() < new Date(this.getBonusPerson().expired)
    ) {
      auth = true;
    } else {
      localStorage.clear();
    }
    return auth;
  }
}
